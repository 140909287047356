
export type T_PARTNER_LIST_ITEM = {
  name:string
  code:string
}
export const PARTNERS_LIST:T_PARTNER_LIST_ITEM[] = [
    {name: "DocHQ",                     code: "dochq"},
]

export const OTHER_PARTNERS_LIST:T_PARTNER_LIST_ITEM[] = [
]


export const CARE_LIST:T_PARTNER_LIST_ITEM[] = [
]

  
  
  
  
  
 