import { Grid, Divider } from "@mui/material";
import useDataLoader from "hooks/useDataLoader";
import ErrorAlert from "components/ErrorAlert"
import DbStatus from 'components/DbStatus';
import Search from "../Search"
import clsx from "clsx";
import PartnerCard from "./PartnerCard";
import { CARE_LIST, PARTNERS_LIST,OTHER_PARTNERS_LIST } from "./partners";
import { MainMenu } from 'components/SideMenu/menus';
import Page from "components/Page";
import StatusIndicator from "components/wg/StatusIndicator";


const cmp = (a:any,b:any) => a > b ? 1 : a < b ? -1 : 0;


export default 
function MainPage(){

  const {loading, data, error} = useDataLoader('data/pusers')
  let CARE_LIST1 = CARE_LIST
  //console.log(data)
  if (data) {
    CARE_LIST1 = []
    for (const [key, value] of Object.entries(data)) {
        if (key.endsWith("@care")) {
            CARE_LIST1.push({name: key.replace(/@care$/,""), code:key},)
        }
    }
    CARE_LIST1.sort((a:any,b:any)=>-cmp(data[a.code].active_users,data[b.code].active_users))
    PARTNERS_LIST.sort((a:any,b:any)=>-cmp(data[a.code].active_users,data[b.code].active_users))
  }

  return (
    <div className={clsx("main-page")}>

      <Page partner="kemtai" menu={MainMenu} current="Main">


          <Grid item sm={12} md={6}><Search /></Grid>

        <Divider sx={{my:3}} />

        { error
            ? <ErrorAlert error={ error } />
            : <><Grid container alignItems="stretch" spacing={3}>


                
                { PARTNERS_LIST.map((part, idx)=>{
                    return (
                      <Grid key={idx} item xs={6} sm={4} md={3} lg={2}>
                        <PartnerCard partner={part} data={ data } />
                      </Grid>
                    )
                  })
                }
              </Grid>

              <Divider sx={{my:3}} />
              

              </>

        }

        <DbStatus />

      </Page>

    </div>
  )
}